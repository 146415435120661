import { AxiosInstance } from 'axios'
import request from './gw-request'

class UserApi {
  constructor(private request: AxiosInstance) {}

  async getUserInfo() {
    const res = await this.request.post('/user/get/user_info')
    return res.data
  }

  async updateRelatedInfo(info: object) {
    return this.request.post('/user/update/related_info', info)
  }
}

const userApi = new UserApi(request)
export default userApi
