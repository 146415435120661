import { TourType } from '@/api/tour.api'
import lockableMenu from '@/components/lock/lockable-menu'
import showUnlockModal from '@/components/lock/unlock-modal'
import TourContainer from '@/components/tour/tour-container'
import withTour from '@/components/tour/with-tour'

import { FunctionName, lock } from '@/store/lock.store'
import { notificationStoreActions, useNotificationStore } from '@/stores/notification.store'
import { bountyStore } from '@/store/bounty.store'
import { usePrevious } from '@ant-design/pro-components'
import { cn } from '@udecode/cn'
import { Badge, Flex } from 'antd'
import { motion } from 'framer-motion'
import { difference } from 'lodash'

import { ArrowUpRight, Send } from 'lucide-react'

import IconHome from '@/components/icons/home.svg'
import IconContribution from '@/components/icons/contribution.svg'
import IconBounty from '@/components/icons/hounty.svg'
import IconQuest from '@/components/icons/quest.svg'
import IconExplore from '@/components/icons/explore.svg'
import IconReputation from '@/components/icons/reputation.svg'
import IconEcosystem from '@/components/icons/ecosystem.svg'
import IconReferral from '@/components/icons/referral.svg'
import IconMail from '@/components/icons/email.svg'
import IconSetting from '@/components/icons/setting.svg'
import IconGitbook from '@/components/icons/gitbook.svg'

import { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSnapshot } from 'valtio'

const TourMenuItem = withTour(TourContainer, [
  {
    name: TourType.Hunting,
    title:
      'Since contributing data in bounty hunting is also considered data submission, records from bounty hunting tasks will also appear in the submission section.',
    disabledInteraction: true,
    showNextButton: true,
    placement: 'right',
    step: 6,
    mask: {
      color: 'transparent',
    },
  },
])

function AnimationDot(props: { show: boolean; customClass?: string }) {
  return (
    <motion.div
      initial={{ scale: 0 }}
      animate={{ scale: props.show ? 1 : 0 }}
      transition={{ duration: 0.3, type: 'spring', bounce: 0.6 }}
      className={[props.customClass ? props.customClass : 'bg-primary', 'h-2', 'w-2', 'rounded-full'].join(' ')}
    ></motion.div>
  )
}

function BountyHuntingMenu() {
  // const { bounty_has_new } = useSnapshot(bountyStore)
  return (
    <div className="gap-12px flex items-center">
      Bounty Hunting
      {/* <AnimationDot show={!!bounty_has_new} customClass="bg-[#dc4446]"></AnimationDot> */}
    </div>
  )
}

function NotificationMenu() {
  const { unread } = useNotificationStore()
  return (
    <div className="gap-12px flex items-center">
      Notification
      <AnimationDot show={unread}></AnimationDot>
    </div>
  )
}

const QuestLabel = () => {
  const { unRewardedTask } = useNotificationStore()
  return (
    <Flex justify="space-between" align="center">
      Quest
      <Badge
        count={unRewardedTask}
        showZero={false}
        style={{
          color: '#fff',
          height: '18px',
          fontSize: '12px',
          padding: '2px 8px',
          borderRadius: '12px',
        }}
        className="[&>.ant-badge-count]:shadow-none"
        size="small"
      />
    </Flex>
  )
}

const items = () => [
  {
    icon: <IconHome color={'white'} size={24} />,
    label: 'Home',
    key: '/app',
  },
  // {
  //   icon: <IconContribution color={'white'} size={24} />,
  //   label: <TourMenuItem>Contribution</TourMenuItem>,
  //   key: 'contribution',
  //   children: [
  //     lockableMenu(
  //       FunctionName.Submission,
  //       {
  //         label: 'Submission',
  //         key: '/app/submission',
  //         lockTip: `Complete all quests within the quest "New user validation quest"`,
  //       },
  //       Send,
  //     ),
  //     {
  //       label: 'Validation',
  //       key: '/app/validation',
  //     },
  //   ],
  // },
  // lockableMenu(FunctionName.BountyHunting, {
  //   icon: IconBounty,
  //   key: '/app/bounty',
  //   label: <BountyHuntingMenu />,
  //   lockTip: `Complete all quests within the quest "New user submission quest"`,
  // }),
  {
    icon: <IconQuest color={'white'} size={24} />,
    key: '/app/quest',
    label: <QuestLabel />,
  },
  // {
  //   icon: <IconExplore color={'white'} size={24} />,
  //   key: '/app/explorer',
  //   label: 'Explorer',
  // },
  // xxx-合并进user profile-xxx
  {
    icon: <IconReferral color={'white'} size={24} />,
    key: '/app/referral',
    label: 'Referral',
  },
  // {
  //   icon: <IconReputation color={'white'} size={24} />,
  //   key: '/app/reputation',
  //   label: 'Reputation',
  // },
  {
    icon: <IconEcosystem color={'white'} size={24} />,
    key: '/app/ecosystem',
    label: 'Leaderboard',
  },
  {
    icon: (
      <div>
        <IconGitbook color="white" />
      </div>
    ),
    // xxx-链接待pd提供-xxx
    key: 'https://docs.codatta.io/codatta',
    label: (
      <div className="flex items-center justify-between">
        <span>Documentation</span>
        <ArrowUpRight strokeWidth={1.25} size={20} />
      </div>
    ),
  },
  {
    icon: <IconMail color={'white'} size={24} />,
    key: '/app/notification',
    label: <NotificationMenu />,
    style: {
      marginTop: 'auto',
    },
  },
  {
    icon: <IconSetting color={'white'} size={24} />,
    key: '/app/settings',
    label: 'User Settings',
  },
]

export type AppNavProps = {
  className?: string
}

function ExpandArrow(props: { expand: boolean }) {
  const { expand } = props

  return (
    <div className="relative hidden h-5 w-5 items-center justify-center lg:flex">
      <div
        className={cn(
          'absolute h-[2px] w-2 translate-x-1/4 rounded-full bg-current transition-all',
          expand ? 'rotate-45' : 'rotate-[-45deg]',
        )}
      ></div>
      <div
        className={cn(
          'absolute h-[2px] w-2 -translate-x-1/4 rounded-full bg-current transition-all',
          expand ? 'rotate-[-45deg]' : 'rotate-[45deg]',
        )}
      ></div>
    </div>
  )
}

function getElementHeightWithMargin(element) {
  const style = window.getComputedStyle(element)
  const height = element.getBoundingClientRect().height
  const marginTop = parseFloat(style.marginTop)
  const marginBottom = parseFloat(style.marginBottom)
  return height + marginTop + marginBottom
}

function AppNavItem(props: { item: any; selectedKeys: string[]; onClick: (item: any) => void }) {
  const { item, selectedKeys, onClick } = props
  const { icon, label, key, children, style, disabled } = props.item
  const [active, setActive] = useState(false)
  const [expand, setExpand] = useState(true)
  const subMenuRef = useRef<HTMLDivElement>(null)
  const [subMenuContainerHeight, setSubMenuContainerHeight] = useState(0)

  useEffect(() => {
    if (selectedKeys) setActive(selectedKeys.includes(key))
  }, [selectedKeys])

  useEffect(() => {
    const children = subMenuRef.current!.children as any
    let height = 0
    for (let i = 0; i < children.length; i++) {
      height += getElementHeightWithMargin(children[i])
    }
    if (expand) setSubMenuContainerHeight(height)
    else setSubMenuContainerHeight(0)
  }, [expand])

  function handleClick(item) {
    if (item.disabled) return
    if (item.children) {
      setExpand(!expand)
    } else onClick(item)
  }

  return (
    <div style={style} className="group relative">
      <div className="py-0.5 pl-4 pr-2 lg:pr-0" onClick={() => handleClick(item)}>
        <div
          className={cn(
            'rounded-4 flex cursor-pointer items-center gap-3 bg-transparent px-4 px-4 py-3 transition-all',
            disabled ? 'cursor-not-allowed' : active ? 'bg-primary font-600 text-white' : 'hover:bg-[#30004010]',
          )}
        >
          <div className="h-6 w-6 text-[24px]">{icon}</div>
          <span className="hidden flex-1 text-[14px] lg:block">{label}</span>
          {children && <ExpandArrow expand={expand}></ExpandArrow>}
        </div>
        {/* <div
          className={cn(
            'rounded-r-4 bg-primary absolute top-0 h-full w-3 transition-all',
            active ? '-left-1.5' : '-left-3',
          )}
        ></div> */}
      </div>
      <div
        ref={subMenuRef}
        className="hidden transform-gpu transition-all lg:block"
        style={{ height: subMenuContainerHeight, overflow: 'hidden', opacity: expand ? 1 : 0 }}
      >
        {children &&
          children.map((child) => {
            return (
              <AppNavItem key={child.key} item={child} onClick={() => handleClick(child)} selectedKeys={selectedKeys} />
            )
          })}
      </div>
      {children && (
        <div
          className={cn(
            'origin-left-top rounded-4 invisible absolute left-[95%] top-0 flex w-40 scale-95 flex-col gap-2 bg-white p-2 opacity-0 shadow-none transition-all lg:hidden',
            'group-hover:visible group-hover:left-[100%] group-hover:scale-100 group-hover:opacity-100 group-hover:shadow-lg',
          )}
        >
          {children.map((child) => {
            return (
              <div
                key={child.key}
                className={cn(
                  child.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                  'rounded-3  p-3 text-gray-100 transition-all',
                  selectedKeys.includes(child.key) ? 'bg-primary text-white' : 'hover:bg-[#30004010]',
                )}
                onClick={() => handleClick(child)}
              >
                {child.label}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

const allItems = items().flatMap((item) => [item, ...((item as any).children ?? [])])

export default function AppNav(props: AppNavProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const selectedKeys = useMemo(
    () => [
      [...allItems.filter(({ key }) => location.pathname.startsWith(key as string))].sort(
        (a, b) => (b.key as string).length - (a.key as string).length,
      )[0].key as string,
    ],
    [location],
  )

  // #region Lock
  const { unlockedFunctions } = useSnapshot(lock)
  const prevUnlockedFunctions = usePrevious(unlockedFunctions)
  const [showUnlockedFunctions, setShowUnlockedFunctions] = useState<FunctionName[]>([])
  useEffect(() => {
    if (prevUnlockedFunctions && prevUnlockedFunctions.length < unlockedFunctions.length) {
      showUnlockModal(difference(unlockedFunctions, prevUnlockedFunctions)[0]).then(() => {
        setShowUnlockedFunctions(unlockedFunctions as FunctionName[])
      })
    } else {
      setShowUnlockedFunctions(unlockedFunctions as FunctionName[])
    }
  }, [unlockedFunctions, prevUnlockedFunctions])
  const menuItems = useMemo(() => items(), [showUnlockedFunctions])
  // #endregion

  function handleMenuClick({ key }) {
    if (/https?:/.test(key)) {
      window.open(key, '_blank')
    } else {
      navigate(key)
      notificationStoreActions.getUnread()
    }
  }

  return (
    <div className="z-1 relative flex flex-1 flex-col">
      {menuItems.map((item) => {
        return <AppNavItem key={item.key} item={item} selectedKeys={selectedKeys} onClick={handleMenuClick} />
      })}
    </div>
  )
}
