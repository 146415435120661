import { useReactGA } from '@/hooks/useReactGA'
import { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { ConfigProvider, Layout } from 'antd'
import TourBar from '@/components/tour/tour-bar'
import SideNav from '@/layouts/app-sider'

import * as Sentry from '@sentry/react'
import SentryErrorFallback from './sentry-error-fallback'
import { notificationStoreActions } from '@/stores/notification.store'
import { checkLogin } from '@/store/auth.store'
import UserDetailLoader from '@/components/common/user-detail-loader'
import { authRedirect } from '@/utils/auth-redirect'
import { channelStoreActions, useChannelStore } from '@/store/channel.store'

export default function AppLayout() {
  useReactGA()
  const { relatedInfo } = useChannelStore()

  useEffect(() => {
    notificationStoreActions.getUnread()
    if (relatedInfo) channelStoreActions.updateRelatedInfo(relatedInfo)
  }, [])

  const result = checkLogin()
  if (!result) {
    const url = authRedirect()
    return <Navigate to={url}></Navigate>
  }

  return (
    <Sentry.ErrorBoundary fallback={SentryErrorFallback}>
      <ConfigProvider>
        <UserDetailLoader>
          <Layout className="mx-auto h-screen max-w-[1168px]">
            <Layout.Header className="z-1002 h-auto bg-transparent p-0 empty:hidden">
              <TourBar />
            </Layout.Header>
            <Layout>
              <Layout.Sider style={{ backgroundColor: 'transparent' }} breakpoint="lg" width={247}>
                <SideNav />
              </Layout.Sider>
              <Layout.Content className="min-w-0 flex-1 overflow-y-scroll py-8 pl-2 pr-6 lg:px-12">
                <Outlet />
              </Layout.Content>
            </Layout>
          </Layout>
        </UserDetailLoader>
      </ConfigProvider>
    </Sentry.ErrorBoundary>
  )
}
