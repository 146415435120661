import ImageEmpty from '@/assets/images/empty.svg'
import { VITE_GA_TRACKING_ID, VITE_IS_LOCAL, VITE_MODE, VITE_SENTRY_URL } from '@/configs/config'
import * as Sentry from '@sentry/react'
import { App, ConfigProvider, Empty } from 'antd'
import { AnimatePresence } from 'framer-motion'
import { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga4'
import { RouterProvider, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import { router } from '@/router.tsx'
import Fallback from '@/layouts/sentry-error-fallback'
import AntdTheme from '@/styles/antd.theme'
import { CodattaConnectContextProvider } from 'codatta-connect'
import 'codatta-connect/dist/style.css'

// for link telegram sdk
import '@/lab/telegram.sdk.js'

import 'virtual:uno.css'
import '@/styles/global.scss'

import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import isToday from 'dayjs/plugin/isToday'

initSentry()
initReactGA()

dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isToday)

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ConfigProvider
    theme={AntdTheme}
    renderEmpty={() => (
      <Empty
        image={
          <div className="flex h-full w-full flex-col items-center justify-center">
            <div className="flex aspect-[1/1] items-center justify-center rounded-full bg-[rgba(255,255,255,0.05)] p-6">
              <img src={ImageEmpty} alt="" className="w-12" />
            </div>
          </div>
        }
      />
    )}
  >
    <CodattaConnectContextProvider>
      <AnimatePresence>
        <App component={false}>
          <Sentry.ErrorBoundary fallback={Fallback}>
            <RouterProvider router={router} />
          </Sentry.ErrorBoundary>
        </App>
      </AnimatePresence>
    </CodattaConnectContextProvider>
  </ConfigProvider>,
)

function initReactGA() {
  ReactGA.initialize([
    {
      trackingId: VITE_GA_TRACKING_ID,
      gaOptions: {
        userId: localStorage.getItem('uid') || undefined,
      },
    },
  ])
}

function initSentry() {
  Sentry.init({
    dsn: VITE_SENTRY_URL,
    environment: VITE_MODE,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    enabled: !VITE_IS_LOCAL,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/app\..+\.codatta\.io/, 'https://app.codatta.io'],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error.
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}
