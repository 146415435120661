import TransitionEffect from '@/components/common/transition-effect'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

function SettingsMenu() {
  const navigate = useNavigate()
  const location = useLocation()

  const menuItems = [
    { label: 'Personal Info', key: '/app/settings/account' },
    { label: 'Notification', key: '/app/settings/notification' },
    { label: 'Reward', key: '/app/settings/reward' },
    { label: 'Reputation', key: '/app/settings/reputation' },
  ]

  function handleMenuClick(item: any) {
    navigate(item.key)
  }

  return (
    <div className="border-r-1px w-147px shrink-0 cursor-pointer border-gray-300">
      {menuItems.map((item) => (
        <div
          key={item.key}
          onClick={() => handleMenuClick(item)}
          className={`px-24px py-12px transition-all duration-300
      ${location.pathname === item.key ? 'border-r-3px border-primary bg-primary bg-opacity-24 text-primary' : ''}`}
        >
          {item.label}
        </div>
      ))}
    </div>
  )
}

export default function SettingsLayout() {
  return (
    <TransitionEffect className="">
      <h1 className="text-32px font-600 leading-32px  m-b-8px ">User Settings</h1>
      <p className="m-b-24px">Manage your account settings and preferences</p>
      <div className="py-24px flex bg-gray-100">
        <SettingsMenu />
        <Outlet />
      </div>
    </TransitionEffect>
  )
}
