import ImageLogo from '@/assets/images/logo-white.png'

import { useNavigate } from 'react-router-dom'
import { useUserStore } from '@/store/user.store'
import { ArrowUpRight, Send } from 'lucide-react'

import { useRef } from 'react'
import AppNav from './app-nav'
import AppUser from './app-user'

function LogoSection() {
  const navigate = useNavigate()
  const timer = useRef(null)
  const { info } = useUserStore()
  function handleMouseEnter() {
    timer.current = setTimeout(() => {
      alert(`Welcome!, ${info?.user_id}`)
    }, 5000)
  }

  function handleMouseLeave() {
    clearTimeout(timer.current)
  }

  return (
    <div
      className="mb-8 hidden items-center pl-8 pt-2 lg:flex"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div>
        <img src={ImageLogo} className="h-6" />
        <a className="hover-text-primary mt-2 flex text-sm" href="https://xny.ai/" target="_blank">
          <span className="mr-1">Powered by XnY</span>
          <ArrowUpRight strokeWidth={1.25} size={20} />
        </a>
      </div>
    </div>
  )
}

export default function AppSider() {
  return (
    <div className="flex h-full w-full flex-col py-6">
      <LogoSection />
      <AppNav className="flex-1" />
      <AppUser />
    </div>
  )
}
